@font-face {
  font-family: 'Modernist';
  src: url('assets/fonts/Modernist.otf') format('opentype');
  font-style: normal;
}

body {
  background-color: #101010;
  font-family: 'Modernist', sans-serif;
  margin: 0;
  overflow-x: hidden;
  height: auto;
  scroll-behavior: smooth;
}

:root {
  --primary-color: #1E90FF;
  /* Adjusted to a professional blue */
  --secondary-color: #FFA500;
  /* Changed to a complementary orange */
  --tertiary-color: #2f00ff;
  /* Changed to a deep purple */
}

/*
1200px larger desktops
1024px iPads and other tablets / small laptop in landscape mode
768px iPads and other tablets in portrait mode
600px small tablets in portrait mode
480px small mobile screens in portrait mode
*/

h1 {
  font-size: 6rem;
  color: white;
  text-align: left;
  margin: 0;
  line-height: 1.1;
  letter-spacing: 2px;

  @media (max-width: 1600px) {
    font-size: 5rem;
  }

  @media (max-width: 1024px) {
    font-size: 4.3rem;
  }

  @media (max-width: 768px) {
    font-size: 3.5rem;
  }

  @media (max-width: 600px) {
    font-size: 3rem;
  }
  
  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: 1.8rem;
  color: white;
  text-align: left;
  line-height: 1.2;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.underline:hover {
  color: var(--secondary-color);
  cursor: pointer;
}

span {
  font-size: inherit;
  color: var(--primary-color);
  text-align: left;
  transition: color 0.3s ease;
}

h4 {
  font-size: 1.2rem;
  color: white;
  text-align: left;
  margin: 0;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
}


/* This styles the scrollbar track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* This styles the scrollbar thumb (the part that's draggable) */
::-webkit-scrollbar-thumb {
  background-color: white;
  /* Blue color for the thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
  border: 3px solid black;
  /* Black border for the thumb */
}

/* This styles the scrollbar itself (width and height) */
::-webkit-scrollbar {
  width: 11px;
  /* Width of the vertical scrollbar */
  height: 10px;
  /* Height of the horizontal scrollbar */
}